<template>
  <li class="list-item">
    <ul class="innerList">
      <li class="dateBlk">
        <div class="dateBlk-date">
          <span class="month" v-if="month">{{ month }} /</span>
          <span class="date">{{ date }}</span>
        </div>
        <span class="day">{{ dayName }}</span>
      </li>
      <li
        class="innerList-item"
        v-for="product in products"
        :key="product.item_id"
      >
        <div class="itemRow">
          <span class="quantity" :class="{ positive: quantity(product) > 0 }">
            <span class="num">
              {{ quantity(product) }}
            </span>
            <span class="unit">{{ product.unit }}</span>
          </span>
        </div>
        <div v-if="edit" class="amount-btn">
          <div
            v-on:click="
              minus({
                products: products,
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item minus"
          ></div>
          <div
            v-on:click="
              plus({
                products: products,
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item plus"
          ></div>
          <div
            v-on:click="
              vacuumMinus({
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item minus"
          ></div>
          <div
            v-on:click="
              vacuumPlus({
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="amount-btn__item plus"
          ></div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "orderWeeklyDetailItem",
  props: {
    year: Number,
    month: Number,
    date: Number,
    edit: Boolean,
    day: Number,
    dayName: String,
    orderNum: Number,
    extra: Boolean,
    products: Array
  },
  data: function() {
    return {
      thisDay: "",
      stDate: null
    };
  },
  created() {
    this.stDate = new Date(this.year, this.month, this.date);
  },
  methods: {
    quantity(product) {
      if (this.extra) {
        return product.vacuumQuantity + product.addVacuumQuantity;
      } else {
        return product.quantity + product.add_quantity;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.list-item {
  width: calc(100% / 7);
  min-width: 160px;
  &.holiday,
  &.sunday {
    .month,
    .date,
    .day {
      color: variables.$sunday;
    }
  }
  &.saturday {
    .month,
    .date,
    .day {
      color: variables.$saturday;
    }
  }
}

.dateBlk {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60px;
  box-sizing: border-box;
  padding: 9px 0;
  border-bottom: solid 1px variables.$bg-gray-2;
  border-right: solid 1px variables.$bg-gray-2;
}

.dateBlk-date {
  display: flex;
  justify-content: center;
  align-items: center;
}

.day {
  margin-bottom: 18px;
}

.innerList-item {
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}

.itemRow {
  display: flex;
  padding: 15px 10px 15px 15px;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 60px;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.quantity {
  color: variables.$txt-default;
  width: 52px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  opacity: 0.2;
  transition: all 0.5s ease-in-out;
  &.positive {
    opacity: 1;
  }
}

.extra {
  color: variables.$primary;
  min-width: 83px;
  &.red {
    color: variables.$negative;
  }
}

.num {
  font-size: 2rem;
  letter-spacing: 0;
  white-space: nowrap;
}

.unit {
  margin: 0 0 3px 5px;
  letter-spacing: 0;
  white-space: nowrap;
}
</style>
